import {graphql} from "gatsby"
import React from "react"
import Layout from "../templates/Page"
import Swiper from 'react-id-swiper';
import Masonry from "react-masonry-component"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const elements = () => {

    const imageGallery1 = [
        {
            id: 1,
            size: "grid-item w-50",
            img: "/img/img-06.jpg",
            heading: "Lorem ipsum dolor sit amet",
            text: "",
            link: "#",
        },
        {
            id: 2,
            size: "grid-item w-25",
            img: "/img/related-articles-03.jpg",
            heading: "Vestibulum gravida elementum tempor",
            text: "",
            link: "#",
        },
        {
            id: 3,
            size: "grid-item w-25",
            img: "/img/related-articles-04.jpg",
            heading: "Lorem ipsum dolor sit amet",
            text: "",
            link: "#",
        },
        {
            id: 4,
            size: "grid-item w-50",
            img: "/img/img-07.jpg",
            heading: "Lorem ipsum dolor sit amet",
            text: "",
            link: "#",
        },
        {
            id: 5,
            size: "grid-item w-25",
            img: "/img/related-articles-03.jpg",
            heading: "Vestibulum gravida elementum tempor",
            text: "",
            link: "#",
        },
        {
            id: 6,
            size: "grid-item w-25",
            img: "/img/related-articles-04.jpg",
            heading: "Lorem ipsum dolor sit amet",
            text: "",
            link: "#",
        }
    ]
    const imageGallery2 = [
        {
            id: 1,
            size: "grid-item w-50",
            img: "/img/img-07.jpg",
            heading: "Lorem ipsum dolor sit amet",
            text: "",
            link: "#",
        },
        {
            id: 2,
            size: "grid-item w-25",
            img: "/img/related-articles-03.jpg",
            heading: "Vestibulum gravida elementum tempor",
            text: "",
            link: "#",
        },
        {
            id: 3,
            size: "grid-item w-25",
            img: "/img/related-articles-04.jpg",
            heading: "Lorem ipsum dolor sit amet",
            text: "",
            link: "#",
        },
        {
            id: 4,
            size: "grid-item w-25",
            img: "/img/related-articles-04.jpg",
            heading: "Vestibulum gravida elementum tempor",
            text: "",
            link: "#",
        },
        {
            id: 5,
            size: "grid-item w-25",
            img: "/img/related-articles-02.jpg",
            heading: "Lorem ipsum dolor sit amet",
            text: "",
            link: "#",
        }
    ]
    const imageGallery3 = [
        {
            id: 1,
            size: "grid-item",
            img: "/img/related-articles-01.jpg",
            heading: "Vestibulum gravida elementum tempor",
            text: "",
            link: "#",
        },
        {
            id: 2,
            size: "grid-item",
            img: "/img/related-articles-02.jpg",
            heading: "Lorem ipsum dolor sit amet",
            text: "",
            link: "#",
        },
        {
            id: 3,
            size: "grid-item",
            img: "/img/related-articles-03.jpg",
            heading: "Vestibulum gravida elementum tempor",
            text: "",
            link: "#",
        },
        {
            id: 4,
            size: "grid-item",
            img: "/img/related-articles-04.jpg",
            heading: "Lorem ipsum dolor sit amet",
            text: "",
            link: "#",
        },
        {
            id: 5,
            size: "grid-item",
            img: "/img/related-articles-01.jpg",
            heading: "Vestibulum gravida elementum tempor",
            text: "",
            link: "#",
        },
        {
            id: 6,
            size: "grid-item",
            img: "/img/related-articles-02.jpg",
            heading: "Lorem ipsum dolor sit amet",
            text: "",
            link: "#",
        }
    ]

    const mapDark = [{
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      },{
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{"color": "#333333"}]
      },{
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      },{
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{"color": "#333333"}]
      },{
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      },{
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{"color": "#ffffff"}]
      },{
        "featureType": "road",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      },{
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [{"color": "#ffffff"}]
      },{
        "featureType": "water",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      }
    ];
    const mapLight = [{
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      },{
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{"color": "#ffffff"}]
      },{
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      },{
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{"color": "#ffffff"}]
      },{
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      },{
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{"color": "#000000"}]
      },{
        "featureType": "road",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      },{
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [{"color": "#ffffff"}]
      },{
        "featureType": "water",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      }
    ];
    const mapBrown = [{
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      },{
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{"color": "#E5E3DF"}]
      },{
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      },{
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{"color": "#E5E3DF"}]
      },{
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      },{
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{"color": "#000000"}]
      },{
        "featureType": "road",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      },{
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [{"color": "#ffffff"}]
      },{
        "featureType": "water",
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
      }
    ];
    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: -34.4183695090691, lng: 150.8939155495682 }}
            defaultOptions={{ styles: mapDark }}
        >
            {props.isMarkerShown && <Marker position={{ lat: -34.4183695090691, lng: 150.8939155495682 }} />}
        </GoogleMap>
    ))

    return (
        <Layout>
            {/* 3 columns -  text */}
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-lg-4">
                            <div className="mb-30">
                                <p className="mb-3 fz-14">What we do for you</p>
                                <h4 className="h2 underline-10px mb-4 pb-2"><a href="#">Home Portfolio</a></h4>
                                <p className="text-muted pb-2">There’s no secret sauce, no wizard behind the curtain. What makes Aerolab tick is an interdisciplinary team with a</p>
                                <a href="#" className="link-primary">More know About us</a>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="mb-30">
                                <p className="mb-3 fz-14">What we do for you</p>
                                <h4 className="h2 underline-10px mb-4 pb-2"><a href="#">Birdy Style Experience</a></h4>
                                <p className="text-muted pb-2">There’s no secret sauce, no wizard behind the curtain. What makes Aerolab tick is an interdisciplinary team with a</p>
                                <a href="#" className="link-primary">More know About us</a>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="mb-30">
                                <p className="mb-3 fz-14">What we do for you</p>
                                <h4 className="h2 underline-10px mb-4 pb-2"><a href="#">Strategy Design Content</a></h4>
                                <p className="text-muted pb-2">There’s no secret sauce, no wizard behind the curtain. What makes Aerolab tick is an interdisciplinary team with a</p>
                                <a href="#" className="link-primary">More know About us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 3 columns - images & text */}
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-lg-4">
                            <div className="mb-30">
                                <div className="mb-4"><a href="#"><img src="https://via.placeholder.com/380" className="img-fluid w-100" /></a></div>
                                <p className="mb-3 fz-14">What we do for you</p>
                                <h4 className="h2 underline-10px mb-4 pb-2"><a href="#">Home Portfolio</a></h4>
                                <p className="text-muted pb-2">There’s no secret sauce, no wizard behind the curtain. What makes Aerolab tick is an interdisciplinary team with a</p>
                                <a href="#" className="link-primary">More know About us</a>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="mb-30">
                                <div className="mb-4"><a href="#"><img src="https://via.placeholder.com/380" className="img-fluid w-100" /></a></div>
                                <p className="mb-3 fz-14">What we do for you</p>
                                <h4 className="h2 underline-10px mb-4 pb-2"><a href="#">Birdy Style Experience</a></h4>
                                <p className="text-muted pb-2">There’s no secret sauce, no wizard behind the curtain. What makes Aerolab tick is an interdisciplinary team with a</p>
                                <a href="#" className="link-primary">More know About us</a>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="mb-30">
                                <div className="mb-4"><a href="#"><img src="https://via.placeholder.com/380" className="img-fluid w-100" /></a></div>
                                <p className="mb-3 fz-14">What we do for you</p>
                                <h4 className="h2 underline-10px mb-4 pb-2"><a href="#">Strategy Design Content</a></h4>
                                <p className="text-muted pb-2">There’s no secret sauce, no wizard behind the curtain. What makes Aerolab tick is an interdisciplinary team with a</p>
                                <a href="#" className="link-primary">More know About us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 2 columns - text */}
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-30">
                                <p className="mb-3 fz-14">What we do for you</p>
                                <h4 className="h2 underline-10px mb-4 pb-2"><a href="#">Birdy Style Experience</a></h4>
                                <p className="text-muted pb-2">There’s no secret sauce, no wizard behind the curtain. What makes Aerolab tick is an interdisciplinary team with a</p>
                                <a href="#" className="link-primary">More know About us</a>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-30">
                                <p className="mb-3 fz-14">What we do for you</p>
                                <h4 className="h2 underline-10px mb-4 pb-2"><a href="#">Strategy Design Content</a></h4>
                                <p className="text-muted pb-2">There’s no secret sauce, no wizard behind the curtain. What makes Aerolab tick is an interdisciplinary team with a</p>
                                <a href="#" className="link-primary">More know About us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* CTA */}
            <section className="section pattern-circle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <h3 className="mb-4 mb-sm-0"><span className="underline-10px">Experience style <br />&amp; simplicity</span></h3>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                            <p className="font-recoleta fz-20">Value style and upfront pricing and information? <br />Enjoy a style led experience and honest interactions with Birdy.</p>
                            <a href="#" className="btn btn-black">Contact us</a>
                        </div>
                    </div>
                </div>
            </section>

            <br />

            {/* CTA Simple */}
            <section className="section pattern-circle">
                <div className="container">
                    <div className="text-center">
                        <h3 className="mb-5"><span className="underline-10px">Experience style &amp; simplicity</span></h3>
                        <a href="#" className="btn btn-black">Contact us</a>
                    </div>
                </div>
            </section>
            
            <br /><br />

            {/* CTA 2 */}
            <section className="section box-left">
                <div className="container">
                    <h3 className="h1 mb-4">Let’s make something <br/>amazing together.</h3>
                    <a href="#" className="link-primary">Let's get started</a>
                </div>
            </section>

            {/* CTA 3 */}
            <section className="section box-left xl-5">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5">
                            <h3 className="h1 mb-4">Let’s <br />make <br />something <br />amazing <br />together.</h3>
                            <a href="#" className="link-primary">Let's get started</a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Content Panel 1 */}
            <section className="content-panel">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-lg">
                            <div className="image" style={{backgroundImage:`url("/img/content-panel-01.jpg")`}}></div>
                            <div className="image-text">
                                <p>“Building with Birdy was an amazing experience. You guided us, inspired us and prduced our dream house - inside and out … And within our budget! Can not thank Birdy enough.”</p>
                                <div className="media">
                                    <img src="/img/avatar-01.jpg" alt="" className="avatar" />
                                    <div className="media-body">
                                        <div>Angel Armstrong</div>
                                        <div className="fz-14">Founder &amp; CEO, Google</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-auto">
                            <div className="text">
                                <h4 className="h3 mb-4"><a href="#" className="highlight-black">We Have Some <br />Awesome Projects.</a></h4>
                                <p>This article is floated online with an aim to help you find the best dvd printing solution.</p>
                                <p>Dvd printing is an important feature used by large and small DVD production houses to print information on DVDs.</p>
                                <a href="#" className="link-primary">See Case Study</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Content Panel 2 */}
            <section className="content-panel">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-lg order-md-2">
                            <div className="image" style={{backgroundImage:`url("/img/content-panel-02.jpg")`}}></div>
                        </div>
                        <div className="col-md-6 col-lg-auto order-md-1">
                            <div className="text">
                                <h4 className="h3 mb-4"><a href="#" className="highlight-black">We Have Some <br />Awesome Projects.</a></h4>
                                <p>This article is floated online with an aim to help you find the best dvd printing solution.</p>
                                <p>Dvd printing is an important feature used by large and small DVD production houses to print information on DVDs.</p>
                                <a href="#" className="link-primary">See Case Study</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Content Panel 3 */}
            <section className="content-panel">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-lg">
                            <div className="image" style={{backgroundImage:`url("/img/content-panel-03.jpg")`}}></div>
                            <div className="image-text">
                                <p>“Building with Birdy was an amazing experience. You guided us, inspired us and prduced our dream house - inside and out … And within our budget! Can not thank Birdy enough.”</p>
                                <div className="media">
                                    <img src="/img/avatar-01.jpg" alt="" className="avatar" />
                                    <div className="media-body">
                                        <div>Angel Armstrong</div>
                                        <div className="fz-14">Founder &amp; CEO, Google</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-auto">
                            <div className="text">
                                <h4 className="h3 mb-4 title-left"><a href="#" className="highlight-black">We Have Some <br />Awesome Projects.</a></h4>
                                <p>This article is floated online with an aim to help you find the best dvd printing solution.</p>
                                <p>Dvd printing is an important feature used by large and small DVD production houses to print information on DVDs.</p>
                                <a href="#" className="link-primary">See Case Study</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Short Article */}
            <section className="section short-article">
                <div className="container">
                    <div className="short-article-header">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="image">
                                    <img src="/img/short-article-01.jpg" alt="" className="w-100 img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-6 d-flex flex-column justify-content-between">
                                <div className="heading">
                                    <h1 className="mb-4">Lorem <br/>ipsum <br/>dolor <br/>sit amet</h1>
                                    <p className="lead">Aliquam sit amet ultrices mi. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Proin condimentum mauris eu quam rhoncus auctor.</p>
                                </div>
                                <div className="footnote">
                                    <div className="text-forever font-weight-bold">Category | Internals | Bathrooms</div>
                                    <div className="font-weight-bold text-gray">10 November 2020</div>
                                    <div className="text-gray"><strong>Written by</strong> <span className="font-recoleta">Josiah Sciascia</span></div>
                                    <div className="text-gray"><strong>Photography by</strong> <span className="font-recoleta">Josiah Sciascia</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="short-article-body">
                        <p>Quisque ultricies vel mi a volutpat. Maecenas sit amet egestas nunc. Quisque id quam posuere, ullamcorper arcu luctus, varius dui. Cras non tempus quam, ac luctus ex. Praesent viverra ante lectus, hendrerit cursus lacus luctus ut. Nunc porta pretium libero id aliquet. Etiam dignissim finibus faucibus. Etiam luctus nisi sed felis tristique, quis blandit orci varius. Pellentesque sollicitudin vel ipsum sed ullamcorper. Cras eget lacinia nunc, non convallis augue. Mauris et nulla ut nisl accumsan ullamcorper. Fusce et dapibus ante.</p>
                        <p>Donec ac venenatis dui, a venenatis est. Mauris vestibulum ultricies metus, eu fringilla nisi aliquet ac. Phasellus urna dui, congue eget augue vel, rhoncus elementum magna. Ut pulvinar, magna ac dictum faucibus, leo lacus aliquam lorem, a hendrerit tellus tortor a libero. </p>
                        <p className="h3 text-center font-weight-normal leading-1 mb-4">Nulla lobortis sollicitudin <br/>leo id sodales.</p>
                        <p>Donec vel nibh quis urna aliquet faucibus. Praesent bibendum augue sapien, vel fringilla augue vestibulum nec. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                        <div className="my-5 px-4">
                            <div className="row no-gutters">
                                <div className="col-6 col-sm-7 col-md-auto">
                                    <img src="/img/short-article-02.jpg" alt="" className="img-fluid" />
                                </div>
                                <div className="col-6 col-sm-5 col-md">
                                    <p className="h3 font-weight-normal leading-1 title-left mt-4">Nulla lobortis sollicitudin leo id sodales. </p>
                                </div>
                            </div>
                        </div>
                        <p>Vestibulum gravida elementum tempor. Integer pulvinar gravida interdum. Aenean commodo dapibus justo non ullamcorper. Sed blandit urna eu ornare condimentum. Quam lectus consequat sapien, rutrum lacinia purus dui quis leo. Pellentesque sed consequat mauris. Fusce volutpat faucibus leo.  Ut vel lorem eget lorem suscipit egestas nec ut dolor. Ut efficitur ex vel ex maximus sodales.</p>
                        <p className="h3 text-center font-weight-normal leading-1 mb-4">Vivamus dapibus, <br/>erat ut suscipit rutrum.</p>
                        <p>Nam tincidunt metus eu mollis pellentesque. Etiam dapibus vulputate ligula non molestie. Etiam felis leo, sollicitudin vel commodo non, malesuada nec justo. Cras neque felis, pulvinar non posuere et, feugiat et velit. In rhoncus nunc lectus, eget iaculis ante tristique viverra.</p>
                    </div>
                </div>
            </section>

            {/* Related Articles */}
            <section className="section">
                <div className="container">
                    <h2 className="h3 mb-4 mb-md-5"><span className="underline-7px">Related Articles</span></h2>
                    <div className="row gutters-10">
                        <div className="col-sm-6 col-lg-3">
                            <div className="grid-item">
                                <img src="/img/related-articles-01.jpg" alt="" />
                                <h4 className="grid-title">Vestibulum gravida elementum</h4>
                                <a href="#" className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <div className="related-articles-category">Category</div>
                                        <div className="related-articles-date">10th November  2020</div>
                                    </div>
                                </a>
                                <button type="button" className="btn-like">
                                    <svg width='30px' height='30px'><title>Like</title><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="grid-item">
                                <img src="/img/related-articles-02.jpg" alt="" />
                                <h4 className="grid-title text-black">Lorem ipsum dolor sit amet</h4>
                                <a href="#" className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <div className="related-articles-category">Category</div>
                                        <div className="related-articles-date">10th November  2020</div>
                                    </div>
                                </a>
                                <button type="button" className="btn-like">
                                    <svg width='30px' height='30px'><title>Like</title><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="grid-item">
                                <img src="/img/related-articles-03.jpg" alt="" />
                                <h4 className="grid-title">Vestibulum gravida elementum tempor</h4>
                                <a href="#" className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <div className="related-articles-category">Category</div>
                                        <div className="related-articles-date">10th November  2020</div>
                                    </div>
                                </a>
                                <button type="button" className="btn-like">
                                    <svg width='30px' height='30px'><title>Like</title><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="grid-item">
                                <img src="/img/related-articles-04.jpg" alt="" />
                                <h4 className="grid-title text-black">Lorem ipsum dolor sit amet</h4>
                                <a href="#" className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <div className="related-articles-category">Category</div>
                                        <div className="related-articles-date">10th November  2020</div>
                                    </div>
                                </a>
                                <button type="button" className="btn-like">
                                    <svg width='30px' height='30px'><title>Like</title><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                
                    <div className="related-articles-nav">
                        <div className="prev">
                            <a href="#" className="h3 underline-7px">
                                <svg width='40px' height='27px' className="arrow-ico">
                                    <use xlinkHref='/img/icons.svg#arrow-left-alt'></use>
                                </svg>
                                Previous
                            </a>
                            <h4>Lorem  ipsum dolor  sit amet</h4>
                            <p>Quisque ultricies vel mi a volutpat. Maecenas sit amet egestas nunc.</p>
                        </div>
                        <div className="next">
                            <a href="#" className="h3 underline-7px">
                                Next
                                <svg width='40px' height='27px' className="arrow-ico">
                                    <use xlinkHref='/img/icons.svg#arrow-right-alt'></use>
                                </svg>
                            </a>
                            <h4>Lorem  ipsum dolor  sit amet</h4>
                            <p>Quisque ultricies vel mi a volutpat. Maecenas sit amet egestas nunc.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Follow Us */}
            <section className="section">
                <div className="container">
                    <h2 className="h3 mb-4 mb-md-5"><span className="underline-7px">Follow us</span></h2>
                    <div className="row gutters-10">
                        <div className="col-sm-6 col-lg-3">
                            <div className="grid-item">
                                <img src="/img/related-articles-01.jpg" alt="" />
                                <h4 className="grid-title">Vestibulum gravida elementum</h4>
                                <a href="#" className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <div className="related-articles-category">Category</div>
                                        <div className="related-articles-date">10th November  2020</div>
                                    </div>
                                </a>
                                <button type="button" className="btn-like">
                                    <svg width='30px' height='30px'><title>Like</title><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="grid-item">
                                <img src="/img/related-articles-02.jpg" alt="" />
                                <h4 className="grid-title text-black">Lorem ipsum dolor sit amet</h4>
                                <a href="#" className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <div className="related-articles-category">Category</div>
                                        <div className="related-articles-date">10th November  2020</div>
                                    </div>
                                </a>
                                <button type="button" className="btn-like">
                                    <svg width='30px' height='30px'><title>Like</title><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="grid-item">
                                <img src="/img/related-articles-03.jpg" alt="" />
                                <h4 className="grid-title">Vestibulum gravida elementum tempor</h4>
                                <a href="#" className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <div className="related-articles-category">Category</div>
                                        <div className="related-articles-date">10th November  2020</div>
                                    </div>
                                </a>
                                <button type="button" className="btn-like">
                                    <svg width='30px' height='30px'><title>Like</title><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="grid-item">
                                <img src="/img/related-articles-04.jpg" alt="" />
                                <h4 className="grid-title text-black">Lorem ipsum dolor sit amet</h4>
                                <a href="#" className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <div className="related-articles-category">Category</div>
                                        <div className="related-articles-date">10th November  2020</div>
                                    </div>
                                </a>
                                <button type="button" className="btn-like">
                                    <svg width='30px' height='30px'><title>Like</title><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                
                </div>
            </section>

            {/* Image Gallery */}
            <section className="section">
                <div className="container">
                    <Masonry className="grid grid-gallery mb-5" options={{ columnWidth: '.grid-sizer', transitionDuration: 0, percentPosition: true }}>
                        <div className="grid-sizer w-25"></div>
                        {imageGallery1.map(el => {
                            return (
                            <a href={el.link} className={el.size} key={el.id}>
                                <img src={el.img} alt="" />
                                <div className="grid-caption">
                                <button
                                    type="button"
                                    className="btn-like"
                                >
                                    <span className="sr-only">Like</span>
                                </button>
                                <h4>{el.heading}</h4>
                                <p>{el.text}</p>
                                </div>
                            </a>
                            )
                        })}
                    </Masonry>
                </div>
            </section>

            {/* Image Gallery 2 */}
            <section className="section">
                <div className="container">
                    <Masonry className="grid grid-gallery mb-5" options={{ columnWidth: '.grid-sizer', transitionDuration: 0, percentPosition: true }}>
                        <div className="grid-sizer w-25"></div>
                        {imageGallery2.map(el => {
                            return (
                            <a href={el.link} className={el.size} key={el.id}>
                                <img src={el.img} alt="" />
                                <div className="grid-caption">
                                <button
                                    type="button"
                                    className="btn-like"
                                >
                                    <span className="sr-only">Like</span>
                                </button>
                                <h4>{el.heading}</h4>
                                <p>{el.text}</p>
                                </div>
                            </a>
                            )
                        })}
                    </Masonry>
                </div>
            </section>
            
            {/* Image Gallery 3 */}
            <section className="section">
                <div className="container">
                    <Masonry className="grid grid-gallery mb-5" options={{ columnWidth: '.grid-sizer', transitionDuration: 0, percentPosition: true }}>
                        <div className="grid-sizer"></div>
                        {imageGallery3.map(el => {
                            return (
                            <a href={el.link} className={el.size} key={el.id}>
                                <img src={el.img} alt="" />
                                <div className="grid-caption">
                                <button
                                    type="button"
                                    className="btn-like"
                                >
                                    <span className="sr-only">Like</span>
                                </button>
                                <h4>{el.heading}</h4>
                                <p>{el.text}</p>
                                </div>
                            </a>
                            )
                        })}
                    </Masonry>
                </div>
            </section>

            {/* Video */}
            <section className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Video full width */}
            <section className="section">
                <div className="embed-responsive embed-responsive-21by9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowFullScreen></iframe>
                </div>
            </section>

            {/* 4 columns - images & text */}
            <div className="section">
                <div className="container">
                    <div className="row gutters-10">
                        <div className="col-sm-6 col-lg-3">
                            <div className="mb-30">
                                <div className="mb-4"><a href="#"><img src="https://via.placeholder.com/380" className="img-fluid w-100" /></a></div>
                                <h4 className="h3 mb-4"><a href="#" className="underline-7px text-black text-decoration-none">First Last</a></h4>
                                <p className="text-muted pb-2">This article is floated online with an aim to help you find the best dvd printing solution.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="mb-30">
                                <div className="mb-4"><a href="#"><img src="https://via.placeholder.com/380" className="img-fluid w-100" /></a></div>
                                <h4 className="h3 mb-4"><a href="#" className="underline-7px text-black text-decoration-none">First Last</a></h4>
                                <p className="text-muted pb-2">This article is floated online with an aim to help you find the best dvd printing solution.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="mb-30">
                                <div className="mb-4"><a href="#"><img src="https://via.placeholder.com/380" className="img-fluid w-100" /></a></div>
                                <h4 className="h3 mb-4"><a href="#" className="underline-7px text-black text-decoration-none">First Last</a></h4>
                                <p className="text-muted pb-2">This article is floated online with an aim to help you find the best dvd printing solution.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="mb-30">
                                <div className="mb-4"><a href="#"><img src="https://via.placeholder.com/380" className="img-fluid w-100" /></a></div>
                                <h4 className="h3 mb-4"><a href="#" className="underline-7px text-black text-decoration-none">First Last</a></h4>
                                <p className="text-muted pb-2">This article is floated online with an aim to help you find the best dvd printing solution.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <h2>What <br />is <br />True <br />Pricing?</h2>
                    <br /><br />
                    <h3><span className="underline-7px">True Pricing includes all <br />the stuff you actually want</span></h3>
                </div>
            </section>

            {/* Articles 3 Columns */}
            <section className="section">
                <div className="container">
                    <div className="row gutters-10">
                        <div className="col-sm-6 col-lg-4">
                            <div className="grid-item">
                                <img src="/img/related-articles-01.jpg" alt="" />
                                <h4 className="grid-title">Vestibulum gravida elementum</h4>
                                <div className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <h4 className="font-walsheim fz-24 text-transform-none mb-4 mb-md-5">Raised 2590 ceilings</h4>
                                        <p className="mb-4 mb-md-5">A workshop aimed at shaping your business idea, answering questions regarding project planning</p>
                                        <a href="#"><strong>Read More</strong></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="grid-item">
                                <img src="/img/related-articles-02.jpg" alt="" />
                                <h4 className="grid-title text-black">Lorem ipsum dolor sit amet</h4>
                                <div className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <h4 className="font-walsheim fz-24 text-transform-none mb-4 mb-md-5">Raised 2590 ceilings</h4>
                                        <p className="mb-4 mb-md-5">A workshop aimed at shaping your business idea, answering questions regarding project planning</p>
                                        <a href="#"><strong>Read More</strong></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="grid-item">
                                <img src="/img/related-articles-03.jpg" alt="" />
                                <h4 className="grid-title">Vestibulum gravida elementum tempor</h4>
                                <div className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <h4 className="font-walsheim fz-24 text-transform-none mb-4 mb-md-5">Raised 2590 ceilings</h4>
                                        <p className="mb-4 mb-md-5">A workshop aimed at shaping your business idea, answering questions regarding project planning</p>
                                        <a href="#"><strong>Read More</strong></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="grid-item">
                                <img src="/img/related-articles-04.jpg" alt="" />
                                <h4 className="grid-title text-black">Lorem ipsum dolor sit amet</h4>
                                <div className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <h4 className="font-walsheim fz-24 text-transform-none mb-4 mb-md-5">Raised 2590 ceilings</h4>
                                        <p className="mb-4 mb-md-5">A workshop aimed at shaping your business idea, answering questions regarding project planning</p>
                                        <a href="#"><strong>Read More</strong></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="grid-item">
                                <img src="/img/related-articles-01.jpg" alt="" />
                                <h4 className="grid-title">Vestibulum gravida elementum</h4>
                                <div className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <h4 className="font-walsheim fz-24 text-transform-none mb-4 mb-md-5">Raised 2590 ceilings</h4>
                                        <p className="mb-4 mb-md-5">A workshop aimed at shaping your business idea, answering questions regarding project planning</p>
                                        <a href="#"><strong>Read More</strong></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="grid-item">
                                <img src="/img/related-articles-04.jpg" alt="" />
                                <h4 className="grid-title text-black">Lorem ipsum dolor sit amet</h4>
                                <div className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <h4 className="font-walsheim fz-24 text-transform-none mb-4 mb-md-5">Raised 2590 ceilings</h4>
                                        <p className="mb-4 mb-md-5">A workshop aimed at shaping your business idea, answering questions regarding project planning</p>
                                        <a href="#"><strong>Read More</strong></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </section>

            {/* Product Filter */}
            <section className="section">
                <div className="container">
                    <div className="row gutters-10">
                        <div className="col-lg-4 mb-2 mb-lg-0 order-lg-4">
                            <div className="form-search-inline">
                                <input type="text" className="form-control form-control-border form-control-md" placeholder="Search Product" />
                                <button type="submit" className="btn btn-md">
                                    <svg width='22px' height='22px' className="d-block"><title>Search</title><use xlinkHref='/img/icons.svg#icon-search-alt'></use></svg>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg col-sm-4 order-lg-1">
                            <div className="dropdown">
                                <button className="btn btn-underline dropdown-toggle btn-block btn-md" type="button" id="dropdownMenuCategory" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Category
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuCategory">
                                    <a className="dropdown-item" href="#">Category Item</a>
                                    <a className="dropdown-item" href="#">Category Item</a>
                                    <a className="dropdown-item" href="#">Category Item</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg col-sm-4 order-lg-2">
                            <div className="dropdown">
                                <button className="btn btn-underline dropdown-toggle btn-block btn-md" type="button" id="dropdownMenuStyle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Style
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuStyle">
                                    <a className="dropdown-item" href="#">Style Item</a>
                                    <a className="dropdown-item" href="#">Style Item</a>
                                    <a className="dropdown-item" href="#">Style Item</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg col-sm-4 order-lg-3">
                            <div className="dropdown">
                                <button className="btn btn-underline dropdown-toggle btn-block btn-md" type="button" id="dropdownMenuPrice" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Price
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuPrice">
                                    <a className="dropdown-item" href="#">Price Item</a>
                                    <a className="dropdown-item" href="#">Price Item</a>
                                    <a className="dropdown-item" href="#">Price Item</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Feature Product */}
            <section className="section">
                <div className="container">
                    <h2 className="h3 mb-4 mb-md-5"><span className="underline-7px">Feature products</span></h2>

                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="card card-product mb-30">
                                <img src="/img/product-09.jpg" alt="" className="card-img" />
                                <div className="card-body">
                                    <h4 className="card-title">Ascot round dining table</h4>
                                    <div className="card-price">$100</div>
                                </div>
                                <div className="card-hover">
                                    <div className="w-100">
                                        <button className="btn btn-gray btn-md">
                                            Add to Furniture List
                                            <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg" className="ml-2">
                                                <path d="M8.018 13C4.655 11.71 1 9.163 1 5.15 1 2.988 1.987 1 5.02 1 6.996 1 8 2.395 8 2.395S9.005 1 10.98 1C14.012 1 15 2.988 15 5.15c0 3.943-3.692 6.524-6.982 7.85z" stroke="#DEB0C2" strokeWidth="2" fill="none" fillRule="evenodd"/>
                                            </svg>
                                        </button>
                                        <button className="btn btn-black btn-md">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="card card-product mb-30">
                                <img src="/img/product-10.jpg" alt="" className="card-img" />
                                <div className="card-body">
                                    <h4 className="card-title">Aero Mirro</h4>
                                    <div className="card-price">$100</div>
                                </div>
                                <div className="card-hover">
                                    <div className="w-100">
                                        <button className="btn btn-gray btn-md">
                                            Add to Furniture List
                                            <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg" className="ml-2">
                                            <path d="M8.018 13C4.655 11.71 1 9.163 1 5.15 1 2.988 1.987 1 5.02 1 6.996 1 8 2.395 8 2.395S9.005 1 10.98 1C14.012 1 15 2.988 15 5.15c0 3.943-3.692 6.524-6.982 7.85z" stroke="#DEB0C2" strokeWidth="2" fill="none" fillRule="evenodd"/>
                                            </svg>
                                        </button>
                                        <button className="btn btn-black btn-md">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="card card-product mb-30">
                                <img src="/img/product-11.jpg" alt="" className="card-img" />
                                <div className="card-body">
                                    <h4 className="card-title">Anchor dining chair</h4>
                                    <div className="card-price">$100</div>
                                </div>
                                <div className="card-hover">
                                    <div className="w-100">
                                        <button className="btn btn-gray btn-md">
                                            Add to Furniture List
                                            <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg" className="ml-2">
                                            <path d="M8.018 13C4.655 11.71 1 9.163 1 5.15 1 2.988 1.987 1 5.02 1 6.996 1 8 2.395 8 2.395S9.005 1 10.98 1C14.012 1 15 2.988 15 5.15c0 3.943-3.692 6.524-6.982 7.85z" stroke="#DEB0C2" strokeWidth="2" fill="none" fillRule="evenodd"/>
                                            </svg>
                                        </button>
                                        <button className="btn btn-black btn-md">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Product */}
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="product sold">
                                <a href="#" className="product-img mb-30">
                                    <img src="/img/img-08.jpg" alt="" />
                                </a>
                                <div className="product-body">
                                    <h4 className="h3 fz-24 mb-4"><a href="#" className="text-black text-decoration-none underline-7px">North Harbour Terrace</a></h4>
                                    <p>120 Street Rd, Suburb, STATE, 2000</p>
                                    <p className="font-recoleta mb-2">A workshop aimed at shaping your business idea, answering questions regarding project planning</p>
                                    <div className="text-forever mb-5">Buyers Guide <strong>$290,000</strong></div>
                                    <div className="d-flex mb-4">
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Room</title><use xlinkHref='/img/icons.svg#icon-room-solid'></use></svg>
                                            1
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Bed</title><use xlinkHref='/img/icons.svg#icon-bed-solid'></use></svg>
                                            4
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Bath</title><use xlinkHref='/img/icons.svg#icon-bath-solid'></use></svg>
                                            2
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Garage</title><use xlinkHref='/img/icons.svg#icon-garage-solid'></use></svg>
                                            2
                                        </div>
                                    </div>
                                    <a href="#" className="btn btn-black btn-md btn-w210">Enquire Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="product sold">
                                <a href="#" className="product-img mb-30">
                                    <img src="/img/img-09.jpg" alt="" />
                                </a>
                                <div className="product-body">
                                    <h4 className="h3 fz-24 mb-4"><a href="#" className="text-black text-decoration-none underline-7px">North Harbour Terrace</a></h4>
                                    <p>120 Street Rd, Suburb, STATE, 2000</p>
                                    <p className="font-recoleta mb-2">A workshop aimed at shaping your business idea, answering questions regarding project planning</p>
                                    <div className="text-forever mb-5">Buyers Guide <strong>$290,000</strong></div>
                                    <div className="d-flex mb-4">
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Room</title><use xlinkHref='/img/icons.svg#icon-room-solid'></use></svg>
                                            1
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Bed</title><use xlinkHref='/img/icons.svg#icon-bed-solid'></use></svg>
                                            4
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Bath</title><use xlinkHref='/img/icons.svg#icon-bath-solid'></use></svg>
                                            2
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Garage</title><use xlinkHref='/img/icons.svg#icon-garage-solid'></use></svg>
                                            2
                                        </div>
                                    </div>
                                    <a href="#" className="btn btn-black btn-md btn-w210">Enquire Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="product sold">
                                <a href="#" className="product-img mb-30">
                                    <img src="/img/img-10.jpg" alt="" />
                                </a>
                                <div className="product-body">
                                    <h4 className="h3 fz-24 mb-4"><a href="#" className="text-black text-decoration-none underline-7px">North Harbour Terrace</a></h4>
                                    <p>120 Street Rd, Suburb, STATE, 2000</p>
                                    <p className="font-recoleta mb-2">A workshop aimed at shaping your business idea, answering questions regarding project planning</p>
                                    <div className="text-forever mb-5">Buyers Guide <strong>$290,000</strong></div>
                                    <div className="d-flex mb-4">
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Room</title><use xlinkHref='/img/icons.svg#icon-room-solid'></use></svg>
                                            1
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Bed</title><use xlinkHref='/img/icons.svg#icon-bed-solid'></use></svg>
                                            4
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Bath</title><use xlinkHref='/img/icons.svg#icon-bath-solid'></use></svg>
                                            2
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Garage</title><use xlinkHref='/img/icons.svg#icon-garage-solid'></use></svg>
                                            2
                                        </div>
                                    </div>
                                    <a href="#" className="btn btn-black btn-md btn-w210">Enquire Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Product */}
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="product">
                                <a href="#" className="product-img mb-30">
                                    <img src="/img/img-08.jpg" alt="" />
                                </a>
                                <div className="product-body">
                                    <h4 className="h3 fz-24 mb-4"><a href="#" className="text-black text-decoration-none underline-7px">Design Name</a></h4>
                                    <p><strong>House Area</strong> 230m2 to 350m2<br />
                                    <strong>Fits lot width</strong> 10m to 12m<br />
                                    <strong>Min Lot depth</strong> 25m</p>
                                    <div className="mb-3"><strong>Layout options</strong></div>
                                    <div className="d-flex mb-4">
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Room</title><use xlinkHref='/img/icons.svg#icon-room-solid'></use></svg>
                                            1
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Bed</title><use xlinkHref='/img/icons.svg#icon-bed-solid'></use></svg>
                                            4
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Bath</title><use xlinkHref='/img/icons.svg#icon-bath-solid'></use></svg>
                                            2
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Garage</title><use xlinkHref='/img/icons.svg#icon-garage-solid'></use></svg>
                                            2
                                        </div>
                                    </div>
                                    <a href="#" className="btn btn-black btn-md btn-w210">Enquire Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="product">
                                <a href="#" className="product-img mb-30">
                                    <img src="/img/img-09.jpg" alt="" />
                                </a>
                                <div className="product-body">
                                    <h4 className="h3 fz-24 mb-4"><a href="#" className="text-black text-decoration-none underline-7px">Design Name</a></h4>
                                    <p><strong>House Area</strong> 230m2 to 350m2<br />
                                    <strong>Fits lot width</strong> 10m to 12m<br />
                                    <strong>Min Lot depth</strong> 25m</p>
                                    <div className="mb-3"><strong>Layout options</strong></div>
                                    <div className="d-flex mb-4">
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Room</title><use xlinkHref='/img/icons.svg#icon-room-solid'></use></svg>
                                            1
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Bed</title><use xlinkHref='/img/icons.svg#icon-bed-solid'></use></svg>
                                            4
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Bath</title><use xlinkHref='/img/icons.svg#icon-bath-solid'></use></svg>
                                            2
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Garage</title><use xlinkHref='/img/icons.svg#icon-garage-solid'></use></svg>
                                            2
                                        </div>
                                    </div>
                                    <a href="#" className="btn btn-black btn-md btn-w210">Enquire Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="product">
                                <a href="#" className="product-img mb-30">
                                    <img src="/img/img-10.jpg" alt="" />
                                </a>
                                <div className="product-body">
                                <h4 className="h3 fz-24 mb-4"><a href="#" className="text-black text-decoration-none underline-7px">Design Name</a></h4>
                                    <p><strong>House Area</strong> 230m2 to 350m2<br />
                                    <strong>Fits lot width</strong> 10m to 12m<br />
                                    <strong>Min Lot depth</strong> 25m</p>
                                    <div className="mb-3"><strong>Layout options</strong></div>
                                    <div className="d-flex mb-4">
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Room</title><use xlinkHref='/img/icons.svg#icon-room-solid'></use></svg>
                                            1
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Bed</title><use xlinkHref='/img/icons.svg#icon-bed-solid'></use></svg>
                                            4
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Bath</title><use xlinkHref='/img/icons.svg#icon-bath-solid'></use></svg>
                                            2
                                        </div>
                                        <div className="product-attr">
                                            <svg width='26px' height='26px' className="d-block mx-auto"><title>Garage</title><use xlinkHref='/img/icons.svg#icon-garage-solid'></use></svg>
                                            2
                                        </div>
                                    </div>
                                    <a href="#" className="btn btn-black btn-md btn-w210">Enquire Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Full Image Product */}
            <section className="section">
                <div className="container">
                    <div className="product-full-img" style={{backgroundImage: `url(/img/img-11.jpg)`}}>
                        <a href="#" className="btn btn-black btn-md btn-w210 btn-enquire">ENQUIRE NOW</a>
                        <div className="product-full-img-caption">
                            <h4 className="h3 fz-24 mb-4"><a href="#" className="underline-7px text-black text-decoration-none">North Harbour Terrace</a></h4>
                            <p>120 Street Rd, Suburb, STATE, 2000</p>
                            <div className="text-forever mb-5">Buyers Guide <strong>$290,000</strong></div>
                            <div className="d-flex">
                                <div className="product-attr no-border">
                                    <div className="icon-border"><svg width='32px' height='32px' className="d-block mx-auto"><title>Room</title><use xlinkHref='/img/icons.svg#icon-room-border'></use></svg></div>
                                    1
                                </div>
                                <div className="product-attr no-border">
                                    <div className="icon-border"><svg width='32px' height='32px' className="d-block mx-auto"><title>Bed</title><use xlinkHref='/img/icons.svg#icon-bed-border'></use></svg></div>
                                    4
                                </div>
                                <div className="product-attr no-border">
                                    <div className="icon-border"><svg width='32px' height='32px' className="d-block mx-auto"><title>Bath</title><use xlinkHref='/img/icons.svg#icon-bath-border'></use></svg></div>
                                    2
                                </div>
                                <div className="product-attr no-border">
                                    <div className="icon-border"><svg width='32px' height='32px' className="d-block mx-auto"><title>Garage</title><use xlinkHref='/img/icons.svg#icon-garage-border'></use></svg></div>
                                    2
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="product-2columns">
                        <div className="row gutters-10">
                            <div className="col-md-6">
                                <div className="product-2columns-img p-4" style={{backgroundImage: `url(/img/img-11.jpg)`}}>
                                    <img src="/img/LOGO_BIRDY_Terraces.png" alt="" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="product-2columns-caption p-4">
                                    <div className="w-100">
                                        <h4 className="h3 fz-24 mb-4"><a href="#" className="underline-7px text-black text-decoration-none">North Harbour Terrace</a></h4>
                                        <p>120 Street Rd, Suburb, STATE, 2000</p>
                                        <div className="text-forever mb-5">Buyers Guide <strong>$290,000</strong></div>
                                        <div className="d-flex justify-content-center mb-5">
                                            <div className="product-attr no-border">
                                                <div className="icon-border"><svg width='32px' height='32px' className="d-block mx-auto"><title>Room</title><use xlinkHref='/img/icons.svg#icon-room-border'></use></svg></div>
                                                1
                                            </div>
                                            <div className="product-attr no-border">
                                                <div className="icon-border"><svg width='32px' height='32px' className="d-block mx-auto"><title>Bed</title><use xlinkHref='/img/icons.svg#icon-bed-border'></use></svg></div>
                                                4
                                            </div>
                                            <div className="product-attr no-border">
                                                <div className="icon-border"><svg width='32px' height='32px' className="d-block mx-auto"><title>Bath</title><use xlinkHref='/img/icons.svg#icon-bath-border'></use></svg></div>
                                                2
                                            </div>
                                            <div className="product-attr no-border">
                                                <div className="icon-border"><svg width='32px' height='32px' className="d-block mx-auto"><title>Garage</title><use xlinkHref='/img/icons.svg#icon-garage-border'></use></svg></div>
                                                2
                                            </div>
                                        </div>
                                        <a href="#" className="btn btn-black btn-md btn-w210 btn-enquire">ENQUIRE NOW</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Form Large */}
            <section className="section">
                <div className="container">
                    <h2 className="text-center mb-5">Enquire today!</h2>
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                            <div className="row gutters-10 mb-5">
                                <div className="col-sm-6">
                                    <div className="form-group form-group-lg">
                                        <label htmlFor="inputName">Name</label>
                                        <input type="text" className="form-control" id="inputName" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group form-group-lg">
                                        <label htmlFor="inputEmail">Email *</label>
                                        <input type="text" className="form-control" id="inputEmail" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group form-group-lg">
                                        <label htmlFor="inputMobile">Mobile</label>
                                        <input type="text" className="form-control" id="inputMobile" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group form-group-lg">
                                        <label htmlFor="inputSuburb">Suburb/Postcode</label>
                                        <input type="text" className="form-control" id="inputSuburb" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-5">
                                <h3>What services do you require*</h3>
                                <div className="row gutters-10 align-items-center mb-2">
                                    <div className="col-6">Price Information</div>
                                    <div className="col-6">
                                        <div className="custom-control custom-checkbox custom-checkbox-heart">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                            <label className="custom-control-label" htmlFor="customCheck1">Finance Assistance</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row gutters-10 align-items-center mb-2">
                                    <div className="col-6">Make an Aoppointment</div>
                                    <div className="col-6">
                                        <div className="custom-control custom-checkbox custom-checkbox-heart">
                                            <input type="checkbox" className="custom-control-input" id="customCheck2" />
                                            <label className="custom-control-label" htmlFor="customCheck2">Rental Appraisal</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-5">
                                <h3>Whart best describes you?</h3>
                                <div className="dropdown">
                                    <button className="btn btn-underline dropdown-toggle btn-block btn-md" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        First home buyer
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <a className="dropdown-item" href="#">Something else here</a>
                                        <a className="dropdown-item" href="#">Action</a>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <button type="submit" className="btn btn-black btn-md btn-w210">SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Form Small */}
            <section className="section">
                <div className="container">
                    <h2 className="text-center mb-5">Enquire today!</h2>
                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <div className="row gutters-10 mb-5">
                                <div className="col-sm-6 col-md">
                                    <div className="form-group form-group-lg">
                                        <label htmlFor="inputName2">Name</label>
                                        <input type="text" className="form-control" id="inputName2" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md">
                                    <div className="form-group form-group-lg">
                                        <label htmlFor="inputEmail2">Email *</label>
                                        <input type="text" className="form-control" id="inputEmail2" />
                                    </div>
                                </div>
                                <div className="col-md-auto text-center">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-black btn-md btn-w210">SUBMIT</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12">
                            <div className="row gutters-10 align-items-center mb-4">
                                <div className="col-lg-auto text-center">
                                    <h2 className="mb-4 mb-lg-0 mr-4">GET ON THE LIST</h2>
                                </div>
                                <div className="col-sm-6 col-lg">
                                    <div className="form-group form-group-lg">
                                        <label htmlFor="inputName2">Name</label>
                                        <input type="text" className="form-control" id="inputName2" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg">
                                    <div className="form-group form-group-lg">
                                        <label htmlFor="inputEmail2">Email *</label>
                                        <input type="text" className="form-control" id="inputEmail2" />
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-black btn-md btn-w210">SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Map */}
            <section className="section">
                <div className="container">
                    <h3 className="text-center mb-5"><span className="underline-7px">Terrace Living</span></h3>

                    <div className="map-border mb-5">
                        <MyMapComponent
                            isMarkerShown={false}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA8uit3OG7Rs_41r52uRTY4hKJNu-5oXGs"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div className="map-inner" />}
                            mapElement={<div style={{ height: `100%` }} />}
                        />
                    </div>

                    <p className="font-recoleta fz-24 text-center">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis <br />praesentium voluptatum deleniti atque corrupti quos dolores </p>
                </div>
            </section>

            {/* Typography */}
            <section className="section bg-lightgray">
                <div className="container-fluid">
                    <h1>Heading 1 - What is True Pricing?</h1>
                    <br /><br />
                    <h2>Heading 2 - What is True Pricing?</h2>
                    <br /><br />
                    <h3><span className="underline-7px">Heading 3 - True Pricing includes all the stuff you actually want</span></h3>
                    <br /><br />
                    <h4>Heading 4 - lorem ipsum dolor sit amet</h4>
                    <br /><br />
                    <h5>Heading 5 - lorem ipsum dolor sit amet</h5>
                    <br /><br />
                    <h6>Heading 6 - lorem ipsum dolor sit amet</h6>
                    <br /><br />
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ornare ipsum neque, in mattis lorem hendrerit eu. Phasellus volutpat lorem sit amet tellus facilisis faucibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                </div>
            </section>

        </Layout>
    );
};

export default elements